import test from './components/test';
import faqAccordion from './components/faqAccordion';

document.addEventListener(
  'DOMContentLoaded',
  () => {
    test();
    faqAccordion();
  },
  false
);
